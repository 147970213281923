
.top-banner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    // margin: calc(var(--header-body-height) * 2) 0 calc(var(--header-body-height) * -2);
    margin: 0;
    padding: 0;
    box-shadow: none !important;
    overflow: hidden;
    &__link {
        display: block;
        width: 100%;
        line-height: 0;
        background-repeat: repeat-x;
        background-position: center;
    }
    &__img {
        width: 100%;
        height: 100%;
    }
    &__img-pc {
        display: none;
        background-position: center;
        background-repeat: repeat-x;
    }
    ::v-deep {
        .v-alert__content {
            width: 100%;
        }
        .v-image__image {
            background-repeat: repeat-x;
        }
        .top-banner {
            &__close {
                background-color: transparent !important;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-banner {
        margin: 0;
        border-bottom: 0;
        &__link {
            max-height: 300px;
        }
    }
}
@media (min-width: 1920px) {
    .top-banner {
        &__img-pc {
            display: block;
            height: 300px;
        }
        &__img-mobile {
            display: none;
        }
    }
}
@media (max-width: 1024px) {
    .top-banner {
        ::v-deep {
            .top-banner {
                &__close {
                    right: 0 !important;
                }
            }
        }
    }
}


.container {
    overflow: visible;
}
.swiper-container {
    overflow: visible;
}
.spaced-text {
    letter-spacing: 2px;
}
.section-title {
    position: relative;
    z-index: 2;
    background-color: #fff;
    height: 100%;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
.sales-button {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
    .v-btn.swiper-button {
        width: 64px !important;
        height: 64px !important;
    }
}


.navigation-tabs {
    .v-tab {
        width: fit-content;
        height: auto;
        padding: 0;
        text-transform: initial;
        color: var(--v-grey-darken3) !important;
    }
    &--2 {
        margin: -8px 0;
        .v-tab {
            font-size: var(--txt-font-size-xs) !important;
            font-weight: 300;
            margin: 8px 0;
            padding-left: 8px;
            &::before {
                display: none;
            }
            &:hover {
                color: #196bdc !important;
            }
        }
    }
}


.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}
.popular-card {
    .popular-card {
        &__button {
            > span {
                transition: all 0.4s;
            }
            > .v-image {
                transition: all 0.2s ease;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
    .popular-card {
        &:hover {
            .popular-card {
                &__button {
                    > span {
                        color: var(--v-primary-base);
                    }
                    .v-image {
                        transform: translateX(4px);
                    }
                }
            }
        }
    }
}


.partnered {
    font-size: var(--txt-font-size-sm);
    color: var(--v-grey-darken3);
    font-weight: 300;
    margin-right: 16px;
}
.family-site-list {
    z-index: 999;
    &__button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 120px;
        font-size: 12px;
        line-height: 1;

        .v-icon {
            font-size: 18px;
        }
    }
}
@media (min-width: 768px) {
    .partnered {
        margin-right: 20px;
    }
    .family-site-list {
        &__button {
            height: 44px;
            width: 140px;
            font-size: 14px;

            .v-icon {
                font-size: 20px;
            }
        }
    }
}


.header-navigation {
    font-family: var(--font-tertiary);
    font-size: 20px;
    margin-left: 20px;
    line-height: 1;
    .navigation-item {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            color: #196bdc;
        }
    }
}
.v-btn.header-menu-button {
    padding: 0;
    height: auto !important;
    font-weight: 300;
    font-size: 10px !important;
    font-family: var(--font-secondary);
    line-height: 1;
    &::before {
        display: none;
    }
    .header-menu-icon {
        margin: 0 auto 4px;
    }
    ::v-deep {
        .v-btn__content {
            display: block;
        }
    }
}
.header-drawer {
    width: 100% !important;
    max-width: 800px;
    padding-top: 24px;
    font-family: var(--font-primary);
    overflow: visible;
    &__underlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: -100vw;
        z-index: -1;
    }
}
.navigation-tabs {
    ::v-deep {
        .v-tabs-bar {
            height: auto;
        }
    }
    .v-tab {
        width: fit-content;
        height: auto;
        padding: 0;
        text-transform: initial;
        color: var(--v-grey-darken3) !important;
        > span {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 0;
                height: 1px;
                background-color: currentColor;
                transition: all 0.2s;
            }
        }
        &::before {
            display: none;
        }
        &:hover {
            color: #196bdc !important;
        }
        &--active {
            color: #002166 !important;
            > span {
                position: relative;
                &::after {
                    width: 100%;
                }
            }
        }
        &--inactive {
            color: var(--v-grey-darken3) !important;
            > span {
                &::after {
                    display: none;
                }
            }

            &.v-tab--productActive {
                color: #002166 !important;
                > span {
                    &::after {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}
.navigation-tab-item-wrap {
    padding: 24px 0;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 1px;
        background-color: #d9dada;
    }
}
.navigation-tabs--1 {
    margin: 10px -10px 20px;
    font-family: var(--font-tertiary);
    .v-tab {
        font-size: var(--tit-font-size-xxs) !important;
        margin: 0 10px;
    }
}
.navigation-tabs--2 {
    margin: -8px 0;
    .v-tab {
        font-size: var(--txt-font-size-xs) !important;
        font-weight: 300;
        margin: 8px 0;
        padding-left: 8px;
    }
}
.navigation-tabs--3 {
    margin: -8px 0;
    .v-tab {
        font-size: var(--txt-font-size-xs) !important;
        font-weight: 300;
        margin: 8px 0;
    }
}
@media (min-width: 1024px) {
    .header-drawer {
        .container {
            padding: 0 40px;
        }
    }
    .navigation-tabs--1 {
        margin: 20px -20px 30px;
        .v-tab {
            margin: 0 20px;
        }
    }
}

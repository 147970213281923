
.collection-card {
    .collection-card {
        &__button {
            > span {
                transition: all 0.4s;
            }
            > .v-image {
                transition: all 0.2s ease;
            }
        }
    }
}
@media (min-width: 768px) {
    .collection-card {
        &:hover {
            .collection-card {
                &__button {
                    > span {
                        color: var(--v-primary-base);
                    }
                    .v-image {
                        transform: translateX(4px);
                    }
                }
            }
        }
    }
}

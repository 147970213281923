
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .section-bg {
        width: calc(100% / 12 * 7);
        height: 100%;
    }
}

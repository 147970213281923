
.header-search-bar {
    position: relative;
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        z-index: 1;
    }
    .v-text-field.v-input {
        ::v-deep {
            .v-input__control {
                .v-input__slot {
                    padding-left: 40px;
                    background-color: var(--v-grey-lighten5);
                }
            }
            input {
                color: var(--v-grey-base);
            }
        }
    }
}


// Page Section
.page-section {
    position: relative;
    padding: var(--page-section-padding-y) 0;
    &--first {
        margin-top: calc(var(--page-section-padding-y) * 2);
    }
    &--last {
        margin-bottom: calc(var(--page-section-padding-y) * -1);
    }
    &--xs {
        padding: var(--page-section-padding-y-xs) 0;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
        &.page-section {
            &--first {
                padding-top: var(--page-section-padding-y);
            }
            &--last {
                padding-bottom: var(--page-section-padding-y);
            }
        }
    }

    &--grey,
    &--bg {
        margin: var(--page-section-padding-y) 0;
    }
    &--grey {
        background-color: var(--v-grey-lighten5);
        padding: calc(var(--page-section-padding-y) * 2) 0;
    }

    &__bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background-color: var(--v-grey-lighten5);
        z-index: -1;
        &--right {
            right: 0;
        }
    }
}

@media (min-width: 768px) {
    .page-section {
        &__bg {
            width: calc(50% + 82px);
            height: 100%;
        }
    }
}


.v-btn.header-menu-button {
    padding: 0;
    height: auto !important;
    font-weight: 300;
    font-size: 10px !important;
    font-family: var(--font-secondary);
    line-height: 1;
    &::before {
        display: none;
    }
    .header-menu-icon {
        margin: 0 auto 4px;
    }
    ::v-deep {
        .v-btn__content {
            display: block;
        }
        .v-badge__badge {
            min-width: 16px;
            height: 16px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--font-secondary);
            font-size: 8px;
            &:hover {
                color: #fff;
            }
        }
    }
}
@media (min-width: 1024px) {
}

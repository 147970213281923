
.footer {
    background-color: var(--v-grey-lighten5);
}
.footer-account {
    font-size: 14px;
    font-weight: bold;
    color: var(--v-primary-base);
}

@media (min-width: 768px) {
    .footer-account {
        font-size: 16px;
    }
}

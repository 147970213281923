
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 7);
        height: 100%;
    }
    .container {
        padding-top: var(--grid-gutter-lg);
        padding-bottom: var(--grid-gutter-lg);
    }
    .story-image-wrap {
        height: calc(638px - var(--grid-gutter-lg) * 2);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: calc(-1 * var(--grid-gutter-lg));
            // left: calc(-1 * var(--grid-gutter-lg));
            aspect-ratio: calc(1133 / 638);
            background-image: url(/images/main/story.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100% + var(--grid-gutter-lg) * 2);
        }
    }
}

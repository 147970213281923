
// header-inner
.header {
    padding: 0 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    .logo {
        width: 126px;
    }
    .header-inner {
        height: 100%;
    }
}
@media (min-width: 1024px) {
    .header {
        padding: 0;
        .logo {
            width: 214px;
        }
        .header-inner {
            padding: 20px;
            max-height: 120px;
        }
    }
}

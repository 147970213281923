
.main-visual {
    &__inner {
        margin: 0 auto;
        // max-width: 1920px;
        aspect-ratio: 15/13;
        overflow: hidden;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    &__text {
        max-width: 410px;
        padding: 16px var(--container-gutter) 16px 0;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -99vw;
            width: 100vw;
            height: 100%;
            background-color: #fff;
            z-index: -1;
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            aspect-ratio: 1900/816;
        }
        &__text {
            max-width: initial;
            padding: 32px 50px 0 0;
        }
    }
}

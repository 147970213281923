
.main-section {
    padding: 40px 0;
    &--first {
        padding-top: 80px;
    }
    &--last {
        padding-bottom: 80px;
    }
}
@media (min-width: 768px) {
    .main-section {
        padding: 60px 0;
        &--first {
            padding-top: 120px;
        }
        &--last {
            padding-bottom: 120px;
        }
    }
}

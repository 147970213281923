
.footer-navigation {
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.footer-top-button {
    position: absolute;
    top: -22px;
    right: 10%;
    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #fff;
    }
    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 768px) {
    .footer-navigation {
        padding-top: 68px;
    }
}


.shop-product-card {
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-out;
    position: relative;
    &:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
    }
    .like-button {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
        &::before {
            display: none;
        }

        .like-button-icon {
            width: 20px;
            height: 20px;
            background-image: url(/images/icon/icon-like.svg);
            background-size: contain;
            transition: all 0.2s ease-out;

            &--active {
                background-image: url(/images/icon/icon-like-active.svg);
            }
        }

        &:hover {
            .like-button-icon {
                background-image: url(/images/icon/icon-like-active.svg);
            }
        }
    }
    &__thumb {
        background-size: contain !important;
    }
    .v-card__actions {
        padding: 16px;
        padding-top: 0;
    }
}


.header-head {
    &__button {
        display: flex;
        align-items: center;
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
    }
    &__icon {
        max-width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}
